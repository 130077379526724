.drive_south {
	animation: drive_south;
	animation-direction: normal;
	animation-timing-function:linear;
}

@keyframes drive_south {
	0% {transform: translate(0,0);}
	100% {transform: translate(73px, 42.5px);}
}

.drive_north {
	animation: drive_north;
	animation-fill-mode: forwards;
	animation-direction: normal;
	animation-timing-function:linear;
}

@keyframes drive_north {
	0% {transform: translate(73px, 42.5px);}
100% {transform: translate(0,0);}
}

@keyframes bounceInUp {
from,
60%,
75%,
90%,
to {
	-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

from {
	opacity: 0;
	-webkit-transform: translate3d(0, 3000px, 0);
	transform: translate3d(0, 3000px, 0);
}

60% {
	opacity: 1;
	-webkit-transform: translate3d(0, -20px, 0);
	transform: translate3d(0, -20px, 0);
}

75% {
	-webkit-transform: translate3d(0, 10px, 0);
	transform: translate3d(0, 10px, 0);
}

90% {
	-webkit-transform: translate3d(0, -5px, 0);
	transform: translate3d(0, -5px, 0);
}

to {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
}
