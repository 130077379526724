/*********************************
**     Dark Mode Overrides      **
*********************************/
.darkmode {

  .list-group-item {
    background-color: #202020;
    border: 1px solid #373737;
  }

}