/*********************************
**      Responsive content      **
*********************************/
@media screen and (max-width: 992px) {


  // For small screen widths, stack the landing boxes and make them smaller
  .landing_shortcut_bg, .landing_shortcut_bg_layer2 {
    width: 240px;
    height: 240px;
  }

  .landing_shortcut_bg_layer2 > svg {
    width: 230px;
    height: 200px;
  }

  .landing_shortcut_text {
    font-size: 34px;
    width: 220px;
    top: 130px;
    left: 10px;
  }

  .landing_shortcut_bg .links_globe_moon > svg {
    top: 70px;
    left: 110px;
  }

  .landing_shortcuts {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

} 




@media only screen and (max-width: 612px) {




}