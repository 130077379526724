/*********************************
**      Responsive content      **
*********************************/
nav {
  .heading_nav_full {
    display: inline-flex;
  }

  .heading_nav_mobile_link, .heading_nav_mobile_close {
    display: None;
  }
}

@media only screen and (max-width: 992px) {

  nav {
    .heading_nav_full {
      display: block;
    }
  
    .heading_nav_mobile_link, .heading_nav_mobile_close {
      display: block;
    }

    .heading_nav_mobile_close {
      position:absolute;
      top: 20px;
      right: 20px;
      cursor:pointer;
      font-size:3rem;
    }

    .heading_nav_full {
      height: 100%; /* 100% Full-height */
      width: 0; /* 0 width default */
      position: fixed; /* Stay in place */
      z-index: 1; /* Stay on top */
      top: 0 !important;
      left: 0;
      background-color: #111; /* Black*/
      overflow-x: hidden; /* Disable horizontal scroll */
      padding: 50px 0 0; /* Place content 60px from the top */
      transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
      border-radius: 0px;

      &.open{
        width: 250px;
      }

      .nav-item, .nav-tools {
        padding: 0px 30px;
        margin: 0;
        font-weight: 500;
        position: relative;

        & > a {
          padding: 0;
          margin: 0;
        }
  
        .ps-dropdown-menu {
          position: initial;
          list-style-type: none;
          background: none;
          box-shadow: none;
          margin: 0;
          padding: 0px;
          transform: scale(1, 1);

          &:first-of-type > li > a {
            padding-top: 5px;
          }
        }

        .ps-dropdown-menu::after, & > a.active::after {
          content: none
        }

      }

    }

  }
  

}


@media only screen and (max-width: 612px) {

  // For tiny screen widths, here we are hiding the text from the ahmburger menu
  nav .heading_nav_mobile_link {
    padding: 0;
    border-radius: 30px;
    margin-top: -15px;
    cursor:pointer;

    .nav-item {
      padding: 5px;
    
      & > span {
        font-size: 66.7px;
        padding: 0px 20px;
      }
    }
  }



  // For tiny screen widths, the date in a header is printed unstyled on a new line
  .blank_title {
    .blank_title_content {
      float: initial;
    }

    time {
      float: initial;
      padding: 0;

      .blank_title_date1 {
        border-right: none;
      }

      .blank_title_date1, .blank_title_date2, .blank_title_month, .blank_title_year, .blank_title_day {
        height: initial;
        float: initial;
        width: initial;
        display: inline;
        font-size: 2rem;
        line-height: 2rem;
        padding-right: 7px;
      }
    }
  }
}
