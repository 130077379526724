

/*********************************
**        Landing Shorcut       **
*********************************/
.landing_shortcuts {
  margin-top: 280px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  transition: margin,.5s;
}


.landing_shortcut_bg {
  vertical-align: middle;
  position:relative;
  animation: slide-in-bottom 0.25s ease-in both;
}
.landing_shortcut_middle {animation-delay: 0.2s}
.landing_shortcut_right  {animation-delay: 0.4s}


.landing_shortcut_bg_layer2 {
  position:relative;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
  background: linear-gradient(to right bottom, #3c3c3c, #181818);
  height: 311px;
  width: 311px;
  animation: bobble-delayed 5s infinite ease both;
}
.landing_shortcut_middle .landing_shortcut_bg_layer2{animation-delay: 4.5s}
.landing_shortcut_right  .landing_shortcut_bg_layer2{animation-delay: 8.3s}
.landing_shortcuts:hover .landing_shortcut_bg_layer2 {animation-duration: 500000s;}

.landing_shortcut_text {
  width:100%;
  position:absolute;
  top:195px;
  left:25px;
  width:250px;
}

.landing_shortcut_bg:hover     .landing_shortcut_text {transform-origin: 50% 50%;}
.landing_shortcut_left:hover   .landing_shortcut_text {animation: slide-in-left 0.25s 0.1s ease-in both;}
.landing_shortcut_middle:hover .landing_shortcut_text {animation: slide-in-bottom 0.25s 0.1s ease-in both;}
.landing_shortcut_right:hover  .landing_shortcut_text {animation: slide-in-right 0.25s 0.1s ease-in both;}


.landing_shortcut_bg h3{
  font-size: 15px;
  line-height: 1.4em;
  text-transform: uppercase;
  letter-spacing: 0.10em;
  text-align: center;
  color: white;
  font-family: 'Lato', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: light;
  margin-bottom: 5px;
}
.landing_shortcut_bg p {
  line-height: 1.3em;
  font-size: 13px;
  margin-top: 5px;
  padding-top: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Lato', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: light;
}


/*********************************
**      Icon Common Elements    **
*********************************/
.landing_shortcut_bg svg {
  position:absolute;
  top: 0px;
  left:5px;
}

/* Star twinkling*/
.landing_shortcut_stars {opacity:0;}
.landing_shortcut_bg:hover .landing_shortcut_stars :nth-child(1) {animation: star_twinkle 2s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
.landing_shortcut_bg:hover .landing_shortcut_stars :nth-child(2) {animation: star_twinkle 1s 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
.landing_shortcut_bg:hover .landing_shortcut_stars :nth-child(3) {animation: star_twinkle 2s 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
.landing_shortcut_bg:hover .landing_shortcut_stars :nth-child(4) {animation: star_twinkle2 1s 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}

.landing_shortcut_bg:hover svg > circle {
  animation: scale-in-center .25s linear forwards;
  transform-origin: center;
}
.landing_shortcut_bg:hover #code_table {
  animation: scale-in-hor-center 0.5s 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transform-origin: center;
}

/*********************************
**           Icon Pages         **
*********************************/
.landing_shortcut_bg:hover #page_page                       {animation: page-fly-right 0.25s 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
.landing_shortcut_bg:hover #page_page_copies g:nth-child(1) {animation: page-fly-right 0.25s 0.40s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
.landing_shortcut_bg:hover #page_page_copies g:nth-child(2) {animation: page-fly-right 0.25s 0.55s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
.landing_shortcut_bg:hover #page_page_copies g:nth-child(3) {animation: page-fly-left  0.25s 0.70s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}
.landing_shortcut_bg:hover #page_page_copies g:nth-child(4) {animation: page-fly-left  0.25s 0.85s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;}



/*********************************
**            Icon Code         **
*********************************/
/*Code*/
.landing_shortcut_bg       #code_window {transform-origin: center;}
.landing_shortcut_bg:hover #code_window {animation: bounce-in-top 0.75s linear forwards;}

.landing_shortcut_bg:hover svg #code_code  path {animation: code_code_scroll 2s ease-out forwards;}
.landing_shortcut_bg:hover svg #code_mouse path {animation: code_mouse_move 2s ease forwards;}

/*********************************
**           Icon Links         **
*********************************/
.landing_shortcut_bg:hover #links_globe {
  animation: icon-globe-drop .5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  transform-origin: center;
  position:absolute;
}
#landing_shortcut_link {z-index:50;}

.landing_shortcut_bg .links_globe_moon {
	position:fixed;
}

.landing_shortcut_bg .links_globe_moon > svg {
  position:absolute;
  transform:scale(0.5);
  top:100px;
  left:145px;
}

.landing_shortcut_bg:hover .links_globe_moon:nth-of-type(4) > svg { animation: icon-hover-scale 3s 0.5s linear both;display:initial; opacity:0;}
.landing_shortcut_bg:hover .links_globe_moon:nth-of-type(4) {animation: icon-hover-revolve 3s 0.5s cubic-bezier(0.500, 0.000, 0.500, 1.000) both;}

.landing_shortcut_bg:hover .links_globe_moon:nth-of-type(2) > svg { animation: icon-hover-scale 3s linear both;display:initial;}
.landing_shortcut_bg:hover .links_globe_moon:nth-of-type(2) {animation: icon-hover-revolve-2 3s cubic-bezier(0.500, 0.000, 0.500, 1.000) both;}

.landing_shortcut_bg:hover .links_globe_moon:nth-of-type(3) > svg { animation: icon-hover-scale 3s 0.75s linear both;display:initial;}
.landing_shortcut_bg:hover .links_globe_moon:nth-of-type(3) {animation: icon-hover-revolve-3 3s 0.75s cubic-bezier(0.500, 0.000, 0.00, 1.000) both;}

@keyframes icon-hover-revolve {
  0%   {z-index:0;   transform: translate(0px, 0px);}
  35%  {z-index:100; transform: translate(-75px, -75px);}
  65%  {z-index:100; transform: translate(75px, 75px);}
  100% {z-index:0;   transform: translate(0px, 0px);}
}

@keyframes icon-hover-revolve-2 {
  0%   {z-index:0;   transform: translate(0px, 0px);}
  35%  {z-index:100; transform: translate(-75px, 75px);}
  65%  {z-index:100; transform: translate(75px, -75px);}
  100% {z-index:0;   transform: translate(0px, 0px);}
}
@keyframes icon-hover-revolve-3 {
  0%   {z-index:0;   transform: translate(0px, 0px);}
  35%  {z-index:100; transform: translate(-100px, 30px);}
  65%  {z-index:100; transform: translate(100px, -30px);}
  100% {z-index:0;   transform: translate(0px, 0px);}
}

@keyframes icon-hover-scale {
  0%   {transform: scale(0.2, 0.2);opacity:0}
  1%   {transform: scale(0.2, 0.2);opacity:1}
  35%  {transform: scale(0.5, 0.5);}
  50%  {transform: scale(1, 1);}
  65%  {transform: scale(0.5, 0.5);}
  100% {transform: scale(0.2, 0.2);opacity:1}
}


/*********************************
**     Other landing elements   **
*********************************/
.landing_heading   {
  height: 150px;
  min-width:625px;
  animation: 0.4s ease-out 0s 1 normal none running slideDown2;
}
.landing_heading .ps_title { margin-top: 80px;}
.landing_heading #menu {margin-top: 70px;}
.landing_heading .ps_search_form {top: 70px;}

.landing_blurb {
  margin-left:80px;
  margin-right:80px;
  font-size: 27px;
  letter-spacing: -1px;
  line-height: 1.1;
  color: #ffffff;
  background-repeat: no-repeat;
  position:absolute;
  z-index: -1;
  top: 230px;
  display:flex;
  gap:50px;
  filter: drop-shadow(2px 2px 1px #101010);
}

.landing_blurb > div {
  max-width: 800px;
  text-justify: none;
  margin-top: 10px;
}
.landing_blurb > div span {
  font-size: 16px;
  display: block;
  line-height: 2.4;
}
.landing_blurb > div span > a {
  text-transform: uppercase;
  color:#c7c7c7;
}
.landing_blurb > div span > span {
  display: inline;
  position:relative;
}
.landing_blurb > div span > span > svg {
  position: absolute;
  top: 0;
  right: -118px;
  transform-origin: top left;
  transform: scale(0.4,0.4);
}
.landing_skip_message {
  opacity: 0;
  color:#ffffff;
  font-size: 14px;
  text-align:center;
  position: absolute;
  top:90vh;
  width: 100%;
}
.landing_footer {
  margin-top: 40px;
}

/*********************************
**      Welcome card stuff      **
*********************************/
.welcome .landing_bg_1 {
  height: 100vh;
  animation: w_bg_1 1s 4s forwards ease;
}
@keyframes w_bg_1 {from{height: 100vh;}to{height: 650px;}}

.welcome .landing_main_menu {
  transform: scale(0, 1);
    animation: w_landing_main_menu 1s 5s forwards ease;
  transform-origin: right;
}
@keyframes w_landing_main_menu {from{transform: scale(0, 1);}to{transform: scale(1, 1);}}

.welcome .landing_shortcut_container .ps_pop {
  visibility:hidden;
  opacity:0;
  animation: w_ps_pop 0.7s forwards ease, 0.7s ease-out fadeIn, fadeInOpcaity 0.7s ease-in forwards;
}
@keyframes w_ps_pop {from{visibility: visible;}to{visibility: visible;}}

.welcome .landing_shortcut_container:nth-child(1) .ps_pop {animation-delay: 5.2s;}
.welcome .landing_shortcut_container:nth-child(2) .ps_pop {animation-delay: 5.4s;}
.welcome .landing_shortcut_container:nth-child(3) .ps_pop {animation-delay: 5.5s;}

.welcome .landing_blurb.ps_pop {
  animation: 0.7s ease-out fadeIn, 0.7s ease-in forwards fadeInOpcaity;
  animation-delay: 5.1s;
}

.welcome .landing_title {
  margin-top: -60px;
  margin-left: -150px;
  top: 50vh;
  left: 50%;
  filter: drop-shadow(1px 1px 0.5px #101010);
  transform: scale(2,2);
  animation: w_landing_title 1s 4s forwards ease;
}
@keyframes w_landing_title {from{top: 50vh;left: 50%;margin-top: -60px;margin-left: -150px;transform: scale(2,2);}to{top: 0vh;left: 0;margin-top: 80px;margin-left: 40px;transform: scale(1,1);filter: drop-shadow(2px 2px 1px #101010);}}

.welcome .landing_title svg {
  transform: translateX(100px) scale(0.36, 0.36);
  animation: w_landing_title_svg 1s 1.3s forwards ease;
}
@keyframes w_landing_title_svg {from{transform: translateX(100px) scale(0.36, 0.36);}to{transform: translateX(0px) scale(0.36, 0.36);}}


.welcome .landing_title .ps_title_major{
  opacity: 0;
  transform: translateY(50px);
  clip-path: polygon(100% 0, 100% 0, 0 100%, 0 100%);
  animation: w_landing_title_span 1s 1.75s forwards ease;
}
.welcome .landing_title .ps_title_minor{
  opacity: 0;
  transform: translateY(50px);
  clip-path: polygon(100% 0, 100% 0, 0 100%, 0 100%);
  animation: w_landing_title_span 1s 2.0s forwards ease;
}
@keyframes w_landing_title_span {from{opacity: 0;transform: translateY(50px);clip-path: polygon(100% 0, 100% 0, 0 100%, 0 100%);}to{opacity: 1;transform: translateY(0px);clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);}}


.welcome footer {
  opacity: 0;
  animation: 0.4s ease-out fadeIn, 0.4s ease-in forwards fadeInOpcaity;
  animation-delay: 4.3s;
}
.welcome .landing_skip_message {
  opacity: 1;
  animation: w_landing_skip_message 0.5s 3.5s forwards ease;
}
@keyframes w_landing_skip_message {from{opacity: 1;}to{opacity: 0;}}

.welcome .slash {
  stroke-dashoffset: 400;
  stroke-dasharray: 395;
  stroke: #fff;
}
.welcome .fill {
  opacity: 0;
  transition: fill 1s 1.5s ease;
}
.welcome .fill {
  opacity: 0;
  fill:#ffffff;
}
.welcome .arrow-1{animation: w_fill1 1s 1.5s forwards ease;}
.welcome .arrow-2{animation: w_fill2 1s 1.6s forwards ease;}
@keyframes w_fill1 {from{fill:#ffffff}to{fill:#33a1ff}}
@keyframes w_fill2 {from{fill:#ffffff}to{fill:#656565}}


/*********************************
**         Comet stuff         **
*********************************/
.comet {
  position:absolute;
  top:0px;
  right:auto;
  bottom:auto;
  left:0px;
  width:2px;
  height:1px;
  background-color: #fff;
  opacity:0;
}
.comet:after{
  content:"";
  background-image:linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,.10));
  position: relative;
  height: 1px;
  display: block;
}

@keyframes comet-sm{0%{opacity:0}25%{opacity:0.4}50%{opacity:0.6}75%{opacity:0.4}to{opacity:0}}
@keyframes comet-md{0%{opacity:0}25%{opacity:0.6}50%{opacity:0.8}75%{opacity:0.6}to{opacity:0}}
@keyframes comet-lg{0%{opacity:0}25%{opacity:0.8}50%{opacity:0.9}75%{opacity:0.8}to{opacity:0}}

@keyframes comet-slow {0%{transform:translateX(0)}to{transform:translateX(70vw)}}
@keyframes comet-med  {0%{transform:translateX(0)}to{transform:translateX(70vw)}}
@keyframes comet-fast {0%{transform:translateX(0)}to{transform:translateX(70vw)}}

.comet-sail-slow.comet-sm {animation: comet-sm 6s linear, comet-slow 6s linear;}
.comet-sail-slow.comet-md {animation: comet-md 6s linear, comet-slow 6s linear;}
.comet-sail-slow.comet-lg {animation: comet-lg 6s linear, comet-slow 6s linear;}
.comet-sail-med.comet-sm  {animation: comet-sm 5s linear, comet-med  5s linear;}
.comet-sail-med.comet-md  {animation: comet-md 5s linear, comet-med  5s linear;}
.comet-sail-med.comet-lg  {animation: comet-lg 5s linear, comet-med  5s linear;}
.comet-sail-fast.comet-sm {animation: comet-sm 4s linear, comet-fast 4s linear;}
.comet-sail-fast.comet-md {animation: comet-md 4s linear, comet-fast 4s linear;}
.comet-sail-fast.comet-lg {animation: comet-lg 4s linear, comet-fast 4s linear;}


.comet-sm:after {padding-left:100px;margin-left:-100px;}
.comet-md:after {padding-left:200px;margin-left:-200px;}
.comet-lg:after {padding-left:300px;margin-left:-300px;}


/*********************************
**       Snowflake stuff        **
*********************************/
@function random_range($min, $max) {
  $rand: random();
  $random_range: calc($min + floor($rand * (($max - $min) + 1)));
  @return $random_range;
}

.snow {
  $total: 200;
  position: absolute;
  width: 6px;
  height: 6px;
  background: white;
  border-radius: 50%;

  @for $i from 1 through $total {
    $random-x: calc(random(1000000) * 0.0001vw);
    $random-offset: calc(random_range(-100000, 100000) * 0.0001vw);
    $random-x-end: calc($random-x + $random-offset);
    $random-x-end-yoyo: calc($random-x + ($random-offset / 2));
    $random-yoyo-time: calc(random_range(30000, 80000) / 100000);
    $random-yoyo-y: calc($random-yoyo-time * 100vh);
    $random-scale: calc(random(10000) * 0.0001);
    $fall-duration: calc(random_range(10, 30) * 1s);
    $fall-delay: calc(random(30) * -1s);

    &:nth-child(#{$i}) {
      opacity: calc(random(5000) * 0.0001 + 0.5);
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
      }
    }
  }
}