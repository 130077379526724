.links-search-container {
	position:relative;
	float: right;
	margin-top: 16px;
}

.links-search-container > input {
	color: #000;
	background: linear-gradient(to right bottom, #d1d1d1, #dedede);
}

.links-container {
	margin-top: 20px;
	display:flex;
	gap: 30px 30px;
	flex-wrap: wrap;
}

.links-item {
	max-width: 227px;
	background: #fff;
	width: 227px;
	min-height: 270px;
	box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32);
	border-radius: 15px;
	position:relative;
	color: #93969c;
	transition: color 0.5s;
	margin-left: 20px;
}

.links-item:hover {
	color: rgb(113, 130, 158);
}

.links-item-image, .links-item-image-dark-overlay {
	width: 170px;
	min-height: 170px;
	border-radius: 15px;
	position:absolute;
	top: 15px;
	left: -20px;
}

.links-item-description {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 140px;
	max-height: 200px;
	transform: translate(-50%,200px);
	color: #fff;
	z-index: 5;
	opacity: 0;
	font-size: 12px;
	transition: opacity 0.25s ease-out, transform 0.5s ease;
}

.links-item-tags {
	position: absolute;
	bottom: 5%;
	left: 50%;
	width: 140px;
	max-height: 50px;
	transform: translateX(-50%);
	z-index: 5;
	opacity: 0;
	transition: opacity 0.25s ease-out, transform 0.5s ease;
	text-align: center;
	display: flex;
	flex-flow: wrap;
	gap: 0.2rem 0.1rem;
}

.links-item-tags .tag {
	font-size: 0.6rem;
	padding: 1px 5px;
	margin-right: 0.25rem;
}

.links-item:hover .links-item-description, .links-item:hover .links-item-tags {
	opacity: 1;
	transition: opacity 0.25s ease 0.1s, transform 0.5s ease;
}

.links-item:hover .links-item-description {
	transform: translate(-50%,-50%);
}

.links-item:hover .links-item-tags {
	transform: translate(-50%,-50%);
}

.links-item-image {
	background: #454545;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 70%;
	box-shadow: 0px 15px 25px -5px rgba(50, 88, 130, 0.92);
}

.links-item-image-dark-overlay {
	background: rgba(0,0,0,.75);
	opacity: 0;
	transition: opacity 0.5s;
	z-index: 4;
}

.links-item:hover .links-item-image-dark-overlay {
	opacity: 1;
}

.links-item-margin {
	position: absolute;
	top: 15px;
	right: 0px;
	min-height: 170px;
	width: 50px;
	padding: 0 10px;
	color: #acb8cc
}

.links-item-margin > div {
	margin: 20px auto;
}


.links-item-title {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 220px;
	top: 190px;
	height: 75px;
}

.links-item-title-stacker {
	position: absolute;
	transform: translateY(-50%);
	top: 50%;
	width:100%;
}

.links-item-page-title, .links-item-domain {
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
	line-height: 1.25em;
	text-align: center;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical; 
}

.links-item-domain {
	font-weight: bold;
	font-size: 1.25rem;
	color: rgb(113, 130, 158);
}

.links-item-page-title {
	font-size: 0.9rem;
	-webkit-line-clamp: 2;
}

.links-item-language-box {
	background: rgb(172, 184, 204);
	color: #fff;
	font-size:14px;
	padding: 5px;
	display: inline-block;
	font-weight: bold;
}