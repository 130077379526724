
/*********************************
**     Dark Mode Overrides      **
*********************************/
.darkmode {

  --body-bg: #0f0f0f;
  --content-fg: #888888;
  --content-fg-secondary: #cccccc;
  --content-bg: #1a1a1a;
  --content-box-bg: #2b2b2b;
  --content-box-bg-shader: #1F1F1F;
  --suspense-bg: #2d2d2d;


  main {
    background-image: linear-gradient(#0f0f0f, var(--content-bg));
    border-top: 2px solid #4d4d4d;
  }


  .blank_title {
    background: rgba(255, 255, 255, 0.05);
  }

  .dm-toggle {
    background: #333333;

    &::before {
      background: #f5f5f5;
    }

    .icons svg {
      fill: #f5f5f5;
    }
  }
  

  .tag {
    background-color: #232323;
    border-color: #232323;
  }
 

  .error_code_bg {
    color: #62626210;
  }


  .links-item {
    background: #232323;
    box-shadow: 0px 15px 35px -5px rgba(0, 0, 0, 0.32);
    color: #7d7d7d;
  }

  .links-item:hover {
    color: #ffffff;
  }

  .links-item-image {
    box-shadow: 0px 15px 35px -5px rgba(0, 0, 0, 0.32);
  }

  .links-item-language-box {
    background: rgb(69, 69, 69);
  }

  .links-item-margin {
    color: #454545;
  }
  
  .links-item-domain {
    color: #ffffff;
  }

  .ps_lineForm button:disabled:not(.pending) {
    background-image: linear-gradient(to right, #1f1e1e, rgb(58, 58, 58));
  }
}