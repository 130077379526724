/*********************************
**           Variables          **
*********************************/
:root {
  --main-theme-color: #31a1ff;
  --main-off-theme-color: #001e56;
  --notification-bg-warn-color: #f39c11;
  --notification-bg-erro-color: #e84c3d;
  --notification-bg-succ-color: #27ae61;
  --notification-bg-info-color: #31a1ff;
  --notification-bg2-warn-color: #b6750d;
  --notification-bg2-erro-color: #ad392c;
  --notification-bg2-succ-color: #1d8248;
  --notification-bg2-info-color: #1961ac;
  --notification-accent-warn-color: #98630c;
  --notification-accent-erro-color: #902f24;
  --notification-accent-succ-color: #1c6f3f;
  --notification-accent-info-color: #135090;




  --body-bg: #1a1a1a;
  --content-fg: #737373;
  --content-fg-secondary: #4d4d4d;
  --content-bg: #ffffff;
  --content-box-bg: #f2f2f2;
  --content-box-bg-shader: #C0C0C0;
  --suspense-bg: #f2f2f2;
}

/*********************************
**        Basic Elements        **
*********************************/
@font-face {
  font-family: League-Gothic;
  src: local('League Gothic'),
       local('LeagueGothic'),
       url(../fonts/leaguegothic-regular-webfont.woff) format('woff');
  font-style: normal;
  font-weight: normal;
}


body {
  margin: 0;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: var(--content-fg);
  font-size: 22px;
  text-align: justify;
  background: var(--body-bg);
}

h1, h2, h3, h4 {text-align: left; color: var(--content-fg-secondary);}
h1:not(:first-child) {margin-top:60px;}

a, .fake_link, a:hover {
  outline: 0;
  text-decoration: none;
  color: var(--main-theme-color);
  cursor:pointer;
}

a[name] {color: var(--content-fg-secondary);}

a .external.fa-2xs {
  vertical-align: 0.5em;
  padding-left: 3px;
}


.centered {
  position:relative;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%)
}

.reading {
  margin-top: 40px;
  line-height: 140%;

  h1, h2, h3, h4, h5, h6 {
    line-height: 100%;
  }

  section {
    margin-bottom: 100px;
  }

  p {
    text-indent: 50px;

    &.footnote {
      text-indent: 0px;
      font-size:16px;
      color:#c7c7c7;
    }

    & > img {
      margin-left: -50px;
      width: 100%;
    }
  }

  div.ps_institution_logos {
    margin-bottom: 0;
    text-align: center;
    
    img {
      height:63px;
      padding:10px 20px;
      vertical-align: middle;
    }
  }

  ul {
    list-style-type: none;

    & > li {
      padding-left:20px;
      background: url(../images/branding/bullet.png) no-repeat left top 12px;
      background-size: 10px 13px;

      ul{
        padding-left: 0px;
        font-size: 19px;
        & > li {
          padding-left: 10px;
          background: none
        }
        & > li:before {
          color: #4e4e4e;
          content: " ├ ";
        }
        & > li:last-of-type:before {
          content: " └ ";
        }
      }
    }
  }

  .reading-table {
    padding: 2px 10px;
    background: rgba(0,0,0,0.05);
    margin-left: auto;
    margin-right: auto;
    border: 0;
    border-spacing: 0;
    border-collapse: separate;
    text-align: center;
    clear: both;
    line-height: 100%;


    th {
      padding: 10px;
      background: var(--main-theme-color);
      color: #fff;
      font-size: 26px;
    }

    tr {

      td:nth-child(odd) {
        background: rgba(0,0,0,0.1);
        padding: 8px 12px;
        text-align: center;
      }
    }
  }
}

.inline-centered{vertical-align: middle;} /*Center icons and other things on text line*/

form {margin: 0;}

ul.tags {
  display: flex;
  flex-flow: row wrap;
  margin-left: 20px;
  margin-right: 20px;
}
ul.tags > li {
  display: inline-block;
  padding: 15px 15px 10px;
  box-shadow: 0 1px 1px #ccc;
  margin: 4px 5px 4px 0;
  text-indent:
  0px; line-height: 17px;
  -webkit-box-shadow: 0 1px 1px #ccc;
  -moz-box-shadow: 0 1px 1px #ccc;
  background: #f1f1f1;
  color: var(--main-theme-color);
}

ul.tags > li:before  {content: None; margin: 0;}
ul.tags > li:hover   {background: var(--main-theme-color); color: #ffffff;}
ul.tags > li:hover a {color: #ffffff;}

.reduced {width: 450px;}
.half {width: 50%;}
.full {width: 100%;}

header {
  background-color: var(--notification-bg-info-color);
  color: #fff; border: 2px var(--notification-accent-info-color) solid; padding: 2px 6px;}

/*********************************
**         Applications         **
*********************************/
.pub_listing {
  margin-bottom: 15px;
  transition: height 1s ease;
}
.pub_listing_small{font-size: 19px;}

.pub_listing h1 {
  display:inline;
  cursor: pointer;
  margin: 10px 0px;
}
.pub_listing .pub_listing_details {
  cursor: pointer;
  font-style: italic;
}

.pub_listing .pub_listing_details_pdf {
  background-image: none;
  padding-left: 0;
  background-position-y: center;
  background-repeat: no-repeat;
}

.pub_listing:not([open]) .pub_listing_details_pdf {
  padding-left: 23px;
  /*background-image: url();*/
  background-size:20px;
  background-repeat: no-repeat;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.8rem 0rem;
}

.tag-container::after {
  content: '';
  flex-grow: 1000000000;
}

.tag {
  border: 2px solid #334155;
  color: #e2e8f0;
  font-size: 0.8rem;
  background-color: #334155;
  border-radius: 0.4rem;
  padding: 0.5rem 0.8rem;
  margin-right: 1rem;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2), 0 0.1rem 0.5rem rgba(0, 0, 0, 0.3), 0 0.2rem 1.5rem rgba(0, 0, 0, 0.4);
  font-weight: 600;
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
}

.tag.active {
  animation: glow 600ms ease-out infinite;
  animation-direction: alternate;
}

@keyframes glow {
  0% {
    border-color: #262c34;
    box-shadow: 0 0 0px rgb(1, 32, 89);
  }	
  100% {
    border-color: #31a0fe;
    box-shadow: 0 0 15px rgb(1, 32, 89);
  }
}

.tag mark {
  background: #31a0fe;
}


.slideOpen_default {
  display:none;
  transform-origin: top;
  transform: scale(1, 0);
  transition: transform 0.3s ease;
}
.slideOpen {
  transform: scale(1, 1);
  display:inline-block;
}

.textarea_plain_text {
  font-family: Consolas;
  font-size: 16px;
  color: rgb(119, 119, 119);
}

.bibtex {
  margin-left: 45px;
}

.error_code_bg {
  color: #00000010;
  top: 50%;
  left: 50%;
  font-size: 600px;
  transform: translate(-50%,-50%);
  position: absolute;
  margin: 0;
  font-weight: 800;
  pointer-events: none;
}

.notification {
  vertical-align: text-top;
  padding: 20px 20px 25px 120px;
  color: #ffffff;
  margin-bottom: 40px;
  min-height: 50px;
}

.notification::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em; /* Adjusts for spacing */
}

.notification > div {
  display: inline-block;
  vertical-align: middle;
}

.notification svg {
  position: absolute;
  top: 23px;
  left: 50px;
  transform:translateX(-50%);
}

.notification a {
  color: #ffffff;
  display: inline-block;
  padding: 10px 10px 7px 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.notification.warning {background: linear-gradient(to right, var(--notification-bg2-warn-color) 100px, var(--notification-bg-warn-color) 100px);}
.notification.error   {background: linear-gradient(to right, var(--notification-bg2-erro-color) 100px, var(--notification-bg-erro-color) 100px);}
.notification.success {background: linear-gradient(to right, var(--notification-bg2-succ-color) 100px, var(--notification-bg-succ-color) 100px);}
.notification.info    {background: linear-gradient(to right, var(--notification-bg2-info-color) 100px, var(--notification-bg-info-color) 100px);}

.notification.warning a {background: var(--notification-bg2-warn-color); border-bottom: 3px solid var(--notification-accent-warn-color);}
.notification.error   a {background: var(--notification-bg2-erro-color); border-bottom: 3px solid var(--notification-accent-erro-color);}
.notification.success a {background: var(--notification-bg2-succ-color); border-bottom: 3px solid var(--notification-accent-succ-color);}
.notification.info    a {background: var(--notification-bg2-info-color); border-bottom: 3px solid var(--notification-accent-info-color);}


.no-transition-before-pageload {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}



/*********************************
**        Common Elements       **
*********************************/
.ps_wrapper {
  height: 100% ;
  max-width: 1160px;
  display:block;
  z-index:3;
  position:absolute;
  top:0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width:100%;
}


.ps_title, .ps_title:hover {
  float: left;
  margin-top: 40px;
  margin-left: 40px;
  display: block;
  color: #ffffff;
  font-family: League-Gothic;
  font-size: 23px;
  line-height: 1;
  height:60px;
  width:300px;
  letter-spacing: 0.9px;
  overflow:hidden;
  filter: drop-shadow(2px 2px 1px #101010);
  position: relative;
}

.ps_title svg {
  transform: scale(0.36, 0.36);
  transform-origin: top left;
}

.ps_title_major, .ps_title_minor, .ps_title_hover_text {
  position: absolute;
  left: 83px
}

.ps_title_major {
  display: block;
  font-size: 42px;
  top: 0px;
  opacity: 1;
  transform: translateY(0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  transition: opacity ease 0.5s 0.15s, transform ease 0.5s 0.15s, clip-path ease 0.5s 0.15s;
}
.ps_title_minor {
  text-transform: uppercase;
  top: 38px;
  opacity: 1;
  transform: translateY(0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  transition: opacity ease 0.5s 0.25s, transform ease 0.5s 0.25s, clip-path ease 0.5s 0.25s;
}

.ps_title_hover_text:nth-child(4) {
  top: 0px;
  opacity: 0;
  transform: translateY(-50px);
  clip-path: polygon(100% 0, 100% 0, 0 100%, 0 100%);
  transition: opacity ease 0.5s 0.05s, transform ease 0.5s 0.05s, clip-path ease 0.5s 0.05s;
}
.ps_title_hover_text:nth-child(5) {
  top: 22px;
  font-size: 42px;
  text-transform: uppercase;
  opacity: 0;
  transform: translateY(-50px);
  clip-path: polygon(100% 0, 100% 0, 0 100%, 0 100%);
  transition: opacity ease 0.5s, transform ease 0.5s, clip-path ease 0.5s;
}


.ps_title_fr{letter-spacing: 1.15px;}

.ps_title .ps_title_highlight {
  color: #999999;
}

.ps_title_can_hover:hover .ps_title_major{
  opacity: 0;
  transform: translateY(50px);
  clip-path: polygon(100% 0, 100% 0, 0 100%, 0 100%);
  transition: opacity ease 0.5s 0.10s, transform ease 0.5s 0.05s, clip-path ease 0.5s 0.05s;
}
.ps_title_can_hover:hover .ps_title_minor{
  opacity: 0;
  transform: translateY(50px);
  clip-path: polygon(100% 0, 100% 0, 0 100%, 0 100%);
  transition: opacity ease 0.5s, transform ease 0.5s, clip-path ease 0.5s;
}
.ps_title_can_hover:hover .ps_title_hover_text:nth-child(4){
  opacity: 1;
  display: inline;
  transform: translateY(0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  transition: opacity ease 0.5s 0.25s, transform ease 0.5s 0.25s, clip-path ease 0.5s 0.25s;
}
.ps_title_can_hover:hover .ps_title_hover_text:nth-child(5){
  opacity: 1;
  display: inline;
  transform: translateY(0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  transition: opacity ease 0.5s 0.15s, transform ease 0.5s 0.15s, clip-path ease 0.5s 0.15s;
}

.ps_content_heading_wrapper {
  animation: '0.4s ease-out 0s 1 normal none running slideUp'
}


nav .heading_nav_full, nav .heading_nav_mobile_link {

  display: inline-flex;
  position: absolute;
  right: 40px;
  top: 30px;
  max-width: 100%;
  background: linear-gradient(to right bottom, #3c3c3c, #181818);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
  padding: 0 20px 0 30px;
  border-radius: 40px;
  line-height: 1rem;

  .nav-item, .nav-tools {
    color: #83818c;
    padding: 12px 10px;
    text-decoration: none;
    transition: 0.3s;
    margin: 0;
    z-index: 1;
    font-weight: 500;
    position: relative;
    font-size: 2rem;

    span, svg {
      transition: color .2s ease-out;
    }


    & > span {
      display: block;
      line-height: 1.3em;
      font-size: 13px;
      margin-top: 5px;
      padding-top: 0;
      color: rgba(255, 255, 255, 0.8);
      font-family: "Lato", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
    & > a {
      padding: 40px 10px 15px;
      margin: -10px 0 -10px -10px;
      text-transform: uppercase;
    }

    a:hover, svg:hover, a.active, a.active {
      color: var(--main-theme-color);
    }
  }

  .nav-item > a, .nav-tools > div {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.4em;
    color: white;
    font-family: "Lato", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .nav-tools > div > div {
    display:inline;
    height: 23px;
    width: 45px;
    margin-right:10px;
  }

  .nav-tools .svg-inline--fa {
    color: white;
    cursor: pointer;
    font-size: 28px;
    padding-bottom: 10px;
    margin-bottom: -10px;
  }

  .nav-item .ps-dropdown-menu:after, .nav-item > a.active:after {
    content:" ";
    position:absolute;
    bottom:0;
    left:8px;
    width:80%;
    height:0;
    overflow:hidden;
    border-bottom: 5px solid var(--main-theme-color);
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
  }

  .nav-item > a.active:after {
    border-bottom: 5px solid var(--main-theme-color);
  }

  .nav-item .ps-dropdown-menu:after, .nav-tools .ps-dropdown-menu:after {
    border-bottom: 5px solid #3c3c3c;
    left:50%;
    transform: translateX(-50%);
  }


  .nav-item .ps-dropdown-menu, .nav-tools .ps-dropdown-menu {
    position: absolute;
    top: 65px;
    left: 0;
    list-style-type: none;
    background: linear-gradient(to right bottom, #3c3c3c, #181818);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    margin: 0;
    padding: 0px;
    min-width: 200px;
    transform: scale(1,0)
  }

  .nav-item:hover .ps-dropdown-menu, .nav-tools-activatable:hover .ps-dropdown-menu {
    transform-origin: top;
    transform: scale(1,1);
    transition: transform .2s ease-out;
  }

  .dropdown-item {
    padding: 5px 20px;
    display: block;
    line-height: 1.3em;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    font-family: "Lato", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  li:first-child .dropdown-item {
    padding-top: 15px;
  }
  li:last-child .dropdown-item {
    padding-bottom: 15px;
  }

}


.landing_heading nav .heading_nav_full, .landing_heading nav .heading_nav_mobile_link {
  top: 75px;
}

:lang(fr) .nav-item > a {font-size: 17px;}
:lang(de) .nav-item > a {font-size: 17px;}



#ps-search-form {
  position: absolute;
  position: absolute;
  right: -9px;
  top: 9px;
  z-index: 3;
}

.ps-search {
  transition: width 0.6s, border-radius 0.6s, background 0.6s, box-shadow 0.6s, opacity 0.6s;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  background: transparent;
  opacity:0;
  z-index: 3;
}

.ps-search:focus, .ps-search-static {
  transition: width 0.6s cubic-bezier(0, 1.1, 0.66, 1.0), border-radius 0.6s, background 0.3s;
  border: none;
  outline: none;
  box-shadow: none;
  padding-left: 30px;
  cursor: text;
  width: 645px;
  border-radius: auto;
  background: #ebebeb;
  color: black;
  opacity:1;
  font-size: 15px;
}

.ps-search.inverted {
  background: linear-gradient(to right bottom, #3c3c3c, #181818);
  color: white;
}

.ps-search-icon{
  position: absolute;
  right: 19px;
  top: 25px;
  z-index: 4;
  transition: top 0.6s, color .2s ease-out;
}
.ps-search-icon.inverted {
  color: white;
}

.ps-search-icon.ps-search-icon-expanded {
  color: #333333 !important;
  top: 17px;
}

.dm-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.dm-toggle-wrapper {
  width: 80px;
  display: inline-block;
  margin: 0px auto; /* Centering for demo */
  box-sizing: border-box;
}

.dm-toggle {
  height: 25px;
  width: 60px;
  background: #f5f5f5;
  border-radius: 40px;
  padding: 3px;
  position: relative;
  transition: background 0.5s ease;;
  cursor: pointer;
  box-sizing: border-box;
}

.dm-toggle::before {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  top: 2px;
  left: 4px;
  border-radius: 10px;
  background: #333333;
  position: absolute;
  z-index: 2;
  transform: translate(0);
  transition: transform 0.5s ease, background 0.5s ease;
  box-sizing: border-box;
}

.dm-toggle.enabled::before {
  transform: translateX(33px);
}

.dm-toggle input {
  opacity: 0;
  position: absolute;
  top: 0;
}

.dm-toggle .icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 2px;
}

.dm-toggle .icons svg {
  fill: #333333;
  height: 20px;
  width: 20px;
  z-index: 0;
}

footer {
  padding-top: 20px;
  padding-bottom: 20px;
  width:100%;
  text-align: center;
  font-size: 14px;
  height: 50px;
}

.ps_footer_menu {
  margin-bottom: 10px;
}

.ps_img_right {
  float: right;
  margin: 0px 0px 22px 40px;
  display: table;
}
.ps_img_left {
  float: left;
  margin: 0px 40px 22px 0px;
  display: table;
}
.ps_img_left > img, .ps_img_right > img {
  max-width: 500px;
}
.ps_img_panorama {
  margin: 22px 0px;
  display: table;
  width: 100%;
}
.ps_img_panorama > img {width: 100%;}
figure > figcaption, .ps_img_panorama > figcaption {
  font-size: 16px;
  padding-top: 5px;
  font-style: italic;
  display: table-caption;
  line-height: 24px;
  caption-side: bottom;
}

.ps_tree_listing {
  display:block;
  font-size: 19px;
  margin-top: -6px;
  margin-left:15px;
}
.ps_tree_listing:before {color: #4e4e4e; content: " ├ ";font-size:21px;}
.ps_tree_listing:last-of-type:before {color: #4e4e4e; content: " └ ";}
.ps_tree_listing:first-of-type {margin-top:0}


.ps_tree_listing_pdf_decorate {
  padding-left: 23px;
  /* background-image: url(images/icons/doc_pdf.svg); */
  background-size:20px;
  background-repeat: no-repeat;
}

@keyframes fadeIn {
  0% {
    -webkit-transform: translateY(40px) scale(0.88);
    transform: translateY(40px) scale(0.88);
  }
  75% {
    -webkit-transform: translateY(-5px) scale(1.02);
    transform: translateY(-5px) scale(1.02);
  }
  100% {
    -webkit-transform: translateY(0) scale(1.0);
    transform: translateY(0) scale(1.0);
  }
}

@keyframes fadeInOpcaity {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes slideDown {
  0% {transform: scale(1, 0);}
  100% {transform: scale(1, 1);}
}
@keyframes slideOutRight {
  0% {transform: scale(0, 1);}
  100% {transform: scale(1, 1);}
}
@keyframes collapseLeft {
  0% {transform: scale(1, 1);}
  100% {transform: scale(0, 1);}
}

@keyframes slideUp {
  0% {transform: translateY(40px);}
  100% {transform: translateY(0);}
}
@keyframes slideDown2 {
  0% {transform: translateY(-40px);}
  100% {transform: translateY(0);}
}


.ps_pop {
  opacity: 0;
  animation: 0.4s ease-out fadeIn, 0.4s ease-in forwards fadeInOpcaity;
}

.ps_pop:nth-child(1) {animation-delay: 0.05s;}
.ps_pop:nth-child(2) {animation-delay: 0.1s;}
.ps_pop:nth-child(3) {animation-delay: 0.15s;}
.ps_pop:nth-child(4) {animation-delay: 0.2s;}
.ps_pop:nth-child(5) {animation-delay: 0.25s;}
.ps_pop:nth-child(6) {animation-delay: 0.3s;}
.ps_pop:nth-child(7) {animation-delay: 0.35s;}
.ps_pop:nth-child(8) {animation-delay: 0.4s;}
.ps_pop:nth-child(9) {animation-delay: 0.45s;}
.ps_pop:nth-child(10) {animation-delay: 0.5s;}
.ps_pop:nth-child(11) {animation-delay: 0.55s;}
.ps_pop:nth-child(12) {animation-delay: 0.60s;}
.ps_pop:nth-child(13) {animation-delay: 0.65s;}
.ps_pop:nth-child(14) {animation-delay: 0.70s;}
.ps_pop:nth-child(15) {animation-delay: 0.75s;}
.ps_pop:nth-child(16) {animation-delay: 0.80s;}
.ps_pop:nth-child(17) {animation-delay: 0.85s;}
.ps_pop:nth-child(18) {animation-delay: 0.90s;}
.ps_pop:nth-child(19) {animation-delay: 0.95s;}

.ps_fadeIn {
  opacity: 0;
  animation: 0.5s ease-in forwards fadeInOpcaity;
}

.ps_slideDown {
  transform-origin: top;
  transform: scale(1, 0);
  animation: 0.4s ease-out 0.3s forwards slideDown;
}

.ps_slideOutRight {
  transform-origin: left;
  transform: scale(0, 1);
  animation: 0.4s ease-out 0.3s forwards slideOutRight;
}
.ps_collapseLeft {
  transform-origin: left;
  animation: 0.4s ease-out 0.3s forwards collapseLeft;
}


.ps_code_block pre, pre.ps_code_block code {
  line-height: 140%;
}

pre.ps_code_block_header {
  padding-left: 20px;
  border-bottom: solid 1px #707070;
}

pre.ps_code_block_footer {
  padding-left: 20px;
  border-top: solid 1px #707070;
}

pre.ps_code_block_numbering {
  float: left;
  padding: 0 5px 0 10px;
  border-right: solid 1px #707070;
  margin-right: 7px;
  text-align:right;
}

pre.ps_code_block_header, pre.ps_code_block_footer, pre.ps_code_block_numbering {
  background: var(--content-box-bg-shader);
  color: var(--content-fg-secondary);
}

div.ps_code_block {
  border: solid 1px #707070;
  background-color: var(--content-box-bg);
}
div.ps_code_block pre { margin: 0; }


.ps_force_wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
.ps_force_wrap a {
  hyphens:none;
}
.ps_table {
  margin-left:auto;
  margin-right:auto;
  border:None;
  border-spacing: 0px;
    border-collapse: separate;
  text-align:center;
  clear:both;
  line-height: 100%;
}
.ps_table th {
  margin:0;
  padding: 8px 12px;
  text-align:center;
  background: rgba(0, 0, 0, 0.1);
  font-size:18px;
}

th.ps_table_header {
  margin:0;
  padding:10px;
  background: var(--main-theme-color);
  color:#ffffff;
  font-size:26px;
}
.ps_table td {
  margin:0;
  padding: 2px 10px;
  background: rgba(0, 0, 0, 0.05);
}

.ps_tooltip{
  position: absolute; /* the parent element must have relative as position*/
  left: 100%;
  top: 50%;
  z-index: 5;
  text-align:left;
  margin-top: -40px;
    margin-left: 14px;
  visibility: hidden;
  font-size: 18px;
}
.ps_tooltip > div{
  background: #ffffff;
  color:var(--content-fg-secondary);
  border-radius: 6px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.4);
  min-width: 250px;
}
.ps_tooltip:before {
  content: '';
  position: absolute;
  left: -14px;
  top: 20px;
  border-style: solid;
  border-width: 10px 14px 10px 0;
  border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}
.ps_tooltip_close > a{
  position: absolute;
  top: 15px;
  right: 20px;
  transition: all 200ms;
  font-size: 26px;
  font-weight: bold;
  text-decoration: none;
  color: var(--content-fg-secondary);
}
.ps_tooltip_close_a:hover {color:#FF0000;}

.ps_tooltip_header{
  font-size:28px;
  padding:15px 15px 0 15px;
  display:block;
  font-weight:bold;
}
.ps_tooltip_subheader{
  padding:8px 15px;
  display:block;
  color:#a8aab2;
}
.ps_tooltip_footer{
  display:block;
  background:#5f5f5f;
  color:#ffffff;
  padding:15px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

div.ps_icon {
  width:20px;
  height:20px;
  background-size: 20px 20px;
  color: transparent;
  overflow:hidden;
  display: inline-block;
  margin:0 5px;
}

/* .ps_icon_windows {background-image:url(images/icons/windows.png)}
.ps_icon_apple {background-image:url(images/icons/apple.png)}
.ps_icon_linux {background-image:url(images/icons/linux.png)}
.ps_icon_android {background-image:url(images/icons/android.png)} */


.loadingIcon {
  opacity: 0;
}

.loadingIcon.active {
  opacity: 1;
  animation: fadeIn 0.3s;
}

.loadingIcon.fadeOut {
  opacity: 0;
  animation: fadeOut 2s;
  pointer-events: none;
}

.loadingIcon > .warning, .loadingIcon > .error {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.loadingIcon > .warning {
  color: #e2a518;
}

.loadingIcon > .error {
  color: #ee1b1b;
}

@keyframes fadeOut {
   0% {opacity: 1;}
   100% {opacity: 0;}
}

@keyframes fadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
}

@keyframes floating {
  0% { transform: translateY(3px); }
  50%  { transform: translateY(0px); }
  100%   { transform: translateY(3px); }
}


/*********************************
** Landing page unique elements **
*********************************/
.landing_bg_1 {
  height: 650px;
  width: 100%;
  background: #1961ac; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, var(--main-off-theme-color), var(--main-theme-color)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, var(--main-off-theme-color), var(--main-theme-color)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, var(--main-off-theme-color), var(--main-theme-color)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, var(--main-off-theme-color), var(--main-theme-color)); /* Standard syntax */
  border-bottom: 1px solid #d1d1d1;
  overflow: hidden;
position: relative;
  /*box-shadow: inset 0 0 100px #000000;*/
}


/*********************************
**           Animations         **
*********************************/
@keyframes code_code_scroll {
  0% {transform: translate(0px, 0px);}
  100% {transform: translate(0px, -150px);}
}

@keyframes code_mouse_move {
  0% {transform: translate(0px, 0px);}
  25% {transform: translate(-25px, -30px);}
  50% {transform: translate(-40px, 0px);}
  100% {transform: translate(-90px, -60px);}
}

@keyframes page-fly-right {
  0% {transform: translate(38px, 18px);opacity: 0;}
  100% {transform: translate(0px, 0px);opacity: 1;}
}
@keyframes page-fly-left {
  0%   {transform: translate(-38px, -18px);opacity: 0;}
  100% {transform: translate(0px, 0px);opacity: 1;}
}

@keyframes icon-globe-drop {
  0% {
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 0%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {transform: translateX(-100px);opacity: 0;}
  100% {transform: translateX(0);opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {transform: translateY(75px);opacity: 0;}
  100% {transform: translateY(0);opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {transform: translateX(100px);opacity: 0;}
  100% {transform: translateX(0);opacity: 1;
  }
}

@keyframes scale-in-hor-center {
  0% {transform: scaleX(0);}
  100% {transform: scaleX(1);}
}

@keyframes scale-in-center {
  0% {transform: scale(0);}
  100% {transform: scale(1);}
}

@keyframes bounce-in-top {
  0% {
    transform: rotate(-20deg) translateX(70px) translateY(100px) scale(0.5);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  28% {
    opacity: 1;
  }
  45% {
    transform: rotate(0deg) translateY(-65px) scale(1);
    animation-timing-function: ease-in;
  }
  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
  }
  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
}

@keyframes bobble-delayed {
  0% {transform: scale(1, 1);}
  80% {transform: scale(1, 1);}
  86% {transform: scale(1.05, 0.95);}
  88% {transform: scale(0.95, 1.05);}
  90% {transform: scale(1.03, 0.97);}
  93% {transform: scale(0.97, 1.03);}
  95% {transform: scale(1.01, 0.99);}
  100% {transform: scale(1, 1);}
}





/*********************************
**  Blank page unique elements  **
*********************************/
.blank_heading {
  height: 150px;
  width: 100%;
  position: relative; /* Critical to ensure that child positioned elements are drawn over static elements under this */
  z-index: 1;
  background: #1961ac; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, var(--main-off-theme-color) , var(--main-theme-color)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, var(--main-off-theme-color), var(--main-theme-color)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, var(--main-off-theme-color), var(--main-theme-color)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, var(--main-off-theme-color) , var(--main-theme-color)); /* Standard syntax */
}

.blank_heading2 {
  height: 150px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 1495px 650px;
  position:absolute;
  top: 0;
  left: 50%;
  right: auto;
  margin-left:-747px;
  opacity: .13;
}

.blank_heading_wrapper {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

main {
  width:100%;
  background: var(--content-bg);
  background-image: linear-gradient(#e0e0e0, var(--content-bg));
  background-size: 100% 188px;
  background-repeat: repeat-x;
  border-top: 2px solid #fff;
  border-bottom: 2px solid var(--content-fg-secondary);
}

main .blank_content {
  min-height: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left:40px;
  padding-right:40px;
  max-width: 1080px;
  box-sizing: initial;
}

.blank_shortcuts {
  display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  margin-top:20px;
}
.blank_shortcut {
  box-shadow: 0 2px 15px #aaa;
  width: 480px;
  text-align: left;
  padding: 8px;
    border-radius: 6px;
    background: #ffffff;
  margin: 20px;
}
.blank_shortcut h4 {
  margin: 0;
  color: #6B757D;
}
.blank_shortcut > span {
  font-size: 11px;
    margin: -5px 0 5px;
}
.blank_shortcut > a > img {
  width: 100%;
}

.blank_title {
  min-height: 90px;
  padding: 20px 25px 20px 35px;
  background: rgba(0, 0, 0, 0.05);
  clear: left;
  margin-bottom: 40px;
  box-sizing: initial;
}

.blank_title_content {
  float: left;
  letter-spacing: 0.9px;
  font-family: League-Gothic;
  font-size: 36px;
  line-height: initial;
}

.blank_title_content h2 {
  margin: 0;
  text-transform: uppercase;
  margin-top: -10px;
  font-weight: 600;
  font-size: 54px;
  line-height: initial;
}

.blank_title_header {
  color: #777777;
  line-height:30px;
}

.blank_title_details {
  float: right;
    padding-top: 4px;
    font-size: 22px;
    text-align: right;
    line-height: 20px;
  font-family: League-Gothic;
  text-transform: uppercase;
  color: #c7c7c7;
}

.blank_title_date1 {
  height: 80px;
  float: left;
  border-right: 2px solid #c7c7c7;
}
.blank_title_date2 {
  height: 80px;
  float: left;
  width: 80px;
  text-align: center;
}

.blank_title_day {
  font-size: 95px;
  font-weight: bold;
  padding-right: 15px;
  line-height: 88px;
}

.blank_title_month {
  display: block;
  font-weight: bold;
  font-size: 50px;
  line-height: 48px;
}

.blank_title_year {
  display: block;
  font-size: 30px;
  line-height: 40px;
}
.blank_title.blank_hero_image {
  background-size:100%;
  height:480px;
  color:#ffffff;
  vertical-align: bottom;
  text-shadow: 2px 2px 1px #101010;
  width: 1100px;
  margin: 0 auto;
  position: relative;
  padding: 1px 0 0;
    background-position-y: 1px;
    background-repeat: no-repeat;
}
.blank_title.blank_hero_image + .blank_content{
  padding-top:0;
}
.blank_title.blank_hero_image > .blank_title_content {
  position: absolute;
    bottom: 30px;
  left: 60px;
}
.blank_title.blank_hero_image > .blank_title_details {
  position: absolute;
    bottom: 35px;
  right: 50px;
}
.blank_title.blank_hero_image span {color:#ffffff;}
.blank_title.blank_hero_image h2 {color:#ffffff;}
.blank_title.blank_hero_image + .blank_content > .notification{margin-top: 40px;}


/*********************************
**          Form Stuff          **
*********************************/
/*  https://codepen.io/colewaldrip/pen/MKmKEJ/  */

.ps_lineForm {
  margin: 0px auto 20px;
  padding: 20px 50px 40px;
  max-width: 750px;
  background: var(--content-box-bg);
  text-align: center;
  box-shadow: 0 5px 20px #00000061;
}

.ps_lineForm_header {
  max-width: 750px;
  margin: -20px -50px 20px;
  background: linear-gradient(to right, var(--main-off-theme-color), var(--main-theme-color));
  background-size: 120% 100%;
  background-position: right bottom;
  padding: 20px 50px 20px;
  color: #fff;
  font-family: League-Gothic;
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
  }

.ps_lineForm label {
  left: 5px;
  top: 26px;
  position: absolute;
  pointer-events: none;
  color: #AAAAAA;
  transition: 0.2s ease-in;
}

.ps_lineForm > div {
  padding-top: 20px;
  position: relative;
  margin-bottom: 15px;
}

.ps_lineFormField, .ps_lineForm textarea, .ps_lineForm select {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  max-width: 750px;
  color: var(--content-fg);
  border: none;
  outline: none;
  padding: 5px 5px;
  font-size: 22px;
  border-bottom: solid 1px var(--content-fg);
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  -ms-transition: box-shadow 0.2s ease-in;
  -o-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
  background-color: transparent;
}

.ps_lineForm select {
  background-color: #f2f2f2;
}

.ps_lineFormField:focus, .ps_lineForm textarea:focus, .ps_lineForm select:focus {
  box-shadow: 0 1px 0 0 #000;
}

.ps_lineFormField:focus+label, .ps_lineForm textarea:focus + label, .ps_lineForm select + label {
  font-size: 14px;
  transform: translateY(-25px);
  transition: 0.2s ease-in;
}

.ps_lineFormField.active + label, .ps_lineForm textarea.active + label, .ps_lineForm select + label {
  font-size: 14px;
  transform: translateY(-25px);
}

/* Checkbox and radio */
.ps_lineForm input[type=checkbox], .ps_lineForm input[type=radio] {
  opacity: 0;
  position: absolute;
  transform: scale(2);
}

.ps_lineForm input[type=checkbox], .ps_lineForm input[type=checkbox]+label, .ps_lineForm input[type=radio], .ps_lineForm input[type=radio]+label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.ps_lineForm input[type=checkbox]+label, .ps_lineForm input[type=radio]+label {
  position: relative;
  top:-5px;
  left:-5px;
}

.ps_lineForm input[type=checkbox] + label:before, .ps_lineForm input[type=radio] + label:before {
  content: '';
  border: 1px solid #000000;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
  padding: 3px;
}

.ps_lineForm input[type=checkbox]:checked + label:before {
    background: var(--main-theme-color);
    background-clip: content-box;
}

.ps_lineForm input[type=radio] + label:before {
    border-radius: 50%;
}

.ps_lineForm input[type=radio]:checked + label:before {
    background: var(--main-theme-color);
}


.ps_lineForm input[type=checkbox]:focus + label, .ps_lineForm input[type=radio]:focus + label {
  outline: 2px solid #ddd; /* focus style */
}

/* Button */
.ps_lineForm button {
  margin-bottom: 10px;
  cursor: pointer;
  font-family: League-Gothic;
  font-size:36px;
  max-width:200px;
  padding:10px 20px;
  background: linear-gradient(to right, var(--main-off-theme-color), var(--main-theme-color));
  background-size: 120% 100%;
  background-position: center bottom;
  text-transform: uppercase;
  color: white;
  border: none;
  letter-spacing: 1px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition:all 0.1s linear;
  border: 2px solid transparent;

  &:focus:not(.disabled):not([disabled]) {
    box-shadow: none;
  }

  &:hover:not(.disabled):not([disabled]) {
    background-size: 200% 100%;
  }

  &.disabled:not(.pending), &:disabled:not(.pending) {
    background-image: linear-gradient(to right, #969696, rgb(210, 210, 210));
    color: var(--content-fg-secondary);
    cursor:initial;
  }

  &.pending {
    animation: 2s gradient-angle infinite linear;
    border: 2px solid transparent;
    background-image: linear-gradient(to right, var(--main-off-theme-color), var(--main-theme-color)), conic-gradient( from var(--gradient-angle), var(--main-off-theme-color) 0%, var(--main-theme-color) 37%, #90caf9 30%, var(--main-theme-color) 33%, var(--main-off-theme-color) 40%, var(--main-off-theme-color)50%, var(--main-theme-color) 77%, #90caf9 80%, var(--main-theme-color) 83%, var(--main-off-theme-color) 90% );
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    cursor:wait;
  }

}

@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0turn;
  inherits: false;
}

@keyframes gradient-angle {
  to {
    --gradient-angle: 1turn;
  }
}

.ps_lineForm .g-recaptcha > div {
  margin-left: auto;
  margin-right: auto;
}


/*********************************
**   Resolution compatibility   **
*********************************/
/*Placed at the bottom to override default styles*/
@media screen and (max-width: 1150px) {


  .landing_shortcut_text {
    font-size: 34px;
    width: 220px;
    top:130px;
    left:10px;
  }
  .landing_footer {
    margin-top: 500px;
  }

}



/**************************************
**   Content Placeholder Animation   **
**************************************/
.suspense {
  background: var(--suspense-bg);
  height: 22px;
  margin-bottom: 13px;
  width: 100%;
  animation: fade 0.25s linear;
}


.load-wraper{
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 44;
  overflow: hidden;
  border-radius: 5px;
}
.activity{
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(to left, rgba(255,255,255, .0), rgba(255,255,255, .1), rgba(255,255,255, .2), rgba(255,255,255, .1), rgba(255,255,255, .0));
  animation: loading 1s infinite;
  z-index: 45;
}

@keyframes loading {
  0%{
      left: -45%;
  }
  100%{
      left: 100%;
  }
}

@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}