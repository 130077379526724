.list-group-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.list-group-item:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.list-group-item {
	background-color: #ebebeb;
  border: 1px solid #777;
  display: block;
  padding: 0.5rem 1rem;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  box-sizing: border-box;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  flex: 0 0 auto;
}

.col-1  {width:  8.33333333%;}
.col-2  {width: 16.66666666%;}
.col-3  {width: 25%;}
.col-4  {width: 33.33333333%;}
.col-5  {width: 41.66666666%;}
.col-6  {width: 50%;}
.col-7  {width: 58.33333333%;}
.col-8  {width: 66.66666666%;}
.col-9  {width: 75%;}
.col-10 {width: 83.3333333%;}
.col-11 {width: 91.6666666%;}
.col-12 {width: 100%;}

@media (min-width: 576px) {
  .col-sm-1  {width:  8.33333333%;}
  .col-sm-2  {width: 16.66666666%;}
  .col-sm-3  {width: 25%;}
  .col-sm-4  {width: 33.33333333%;}
  .col-sm-5  {width: 41.66666666%;}
  .col-sm-6  {width: 50%;}
  .col-sm-7  {width: 58.33333333%;}
  .col-sm-8  {width: 66.66666666%;}
  .col-sm-9  {width: 75%;}
  .col-sm-10 {width: 83.3333333%;}
  .col-sm-11 {width: 91.6666666%;}
  .col-sm-12 {width: 100%;}
}


@media (min-width: 768px) {
  .col-md-1  {width:  8.33333333%;}
  .col-md-2  {width: 16.66666666%;}
  .col-md-3  {width: 25%;}
  .col-md-4  {width: 33.33333333%;}
  .col-md-5  {width: 41.66666666%;}
  .col-md-6  {width: 50%;}
  .col-md-7  {width: 58.33333333%;}
  .col-md-8  {width: 66.66666666%;}
  .col-md-9  {width: 75%;}
  .col-md-10 {width: 83.3333333%;}
  .col-md-11 {width: 91.6666666%;}
  .col-md-12 {width: 100%;}
}

.mt-0 {margin-top: 0 !important;}
.mt-2 {margin-top: .5rem !important;}
.mt-4 {margin-top: 1rem !important;}

@media (min-width: 576px) {
  .mt-sm-0 {margin-top: 0 !important;}
  .mt-sm-2 {margin-top: .5rem !important;}
  .mt-sm-4 {margin-top: 1rem !important;}
}

@media (min-width: 768px) {
  .mt-md-0 {margin-top: 0 !important;}
  .mt-md-2 {margin-top: .5rem !important;}
  .mt-md-4 {margin-top: 1rem !important;}
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip {
  --bs-tooltip-color: #9a9a9a;
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  word-wrap: break-word;
  display: block;
  font-family: system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Noto Sans,Liberation Sans,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-family: var(--bs-font-sans-serif);
  font-size: var(--bs-tooltip-font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  margin: var(--bs-tooltip-margin);
  opacity: 0;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: var(--bs-tooltip-zindex);
  box-sizing: border-box;
}
.fade {
  transition: opacity .15s linear;
}

.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
  top: calc(var(--bs-tooltip-arrow-height)*-1);
}

.tooltip .tooltip-arrow {
  display: block;
  height: var(--bs-tooltip-arrow-height);
  width: var(--bs-tooltip-arrow-width);
}

.tooltip-inner {
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
  color: var(--bs-tooltip-color);
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  text-align: center;
}